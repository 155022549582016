import { toast } from "react-toastify";

export const toastError = (message) => {
  toast.clearWaitingQueue();
  return (
  toast.error(message, {
    toastId: 'customId',
    position: "top-center",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  })
)};

export const toastSuccess = (message) => {
  toast.clearWaitingQueue();
  return (
  toast.success(message, {
    toastId: 'success',
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  })
)};
