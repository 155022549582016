import { createModel } from "@rematch/core";
import http from "../utils/http";
import { jwtDecode } from "jwt-decode";
import { toastError } from "../utils/toastHelper";

const UserStore = createModel()({
  name: "users",
  state: {
    data: {},
    token: '',
  },
  reducers: {
    loggedInReducerData(state, payload) {
      return {
        ...state,
        data: payload,
      };
    },
    tokenReducer(state, payload) {
      return {
        ...state,
        token: payload,
      };
    },
    logOutReducer(state,) {
      return {
        ...state,
        token: '',
        data: {}, // Clear user data on logout
      };
    },
  },
  effects: (dispatch) => ({
    async logout() {
      dispatch.users.logOutReducer();
    },
    async login({ username, password }) {
      try {
        const user = await http.post('v1/auth/login', {
          username,
          password
        });

        if (user?.data?.accessToken) {
          const tokenDecoded = jwtDecode(user.data.accessToken);
          user.data.roles = tokenDecoded.roles;

          dispatch.users.loggedInReducerData(user.data);
          dispatch.users.tokenReducer(user.data.accessToken);
        } else {
          dispatch.users.loggedInReducerData(null);
          dispatch.users.tokenReducer('');
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          toastError(error.response.data.message);
        }
      }
    },
  }),
});

export default UserStore;
