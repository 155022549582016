import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { USER_ROLES } from "../../constants/user.const";

const AdminGuard = ({user, children}) => {
  if (user.roles !== USER_ROLES.ADMIN) {
    return <Navigate to='/'/>;
  }

  return children;
}

const mapState = (state) => ({
  user: state.users.data,
});

export default connect(mapState, {})(AdminGuard);
