import React from 'react'
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

const AuthGuard = ({ token, children }) => {
  if (!token) {
    return <Navigate to='/login' />;
  }

  return children;
}

const mapState = (state) => ({
  token: state.users.token,
});

export default connect(mapState, {})(AuthGuard)