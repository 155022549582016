import React from 'react'
import { connect } from 'react-redux'

const Profile = ({ user }) => {
  return (
    <div className='h-full min-h-screen pt-10 flex items-center justify-center'>
      <div className='h-96 w-full max-w-56 shadow-xl bg-black bg-opacity-25 flex flex-col items-center rounded-lg'>
        <div className="flex rounded-full bg-emerald-950 w-24 h-24 text-white justify-center items-center cursor-pointer relative bottom-11">
          <div className='capitalize'>{user?.userDetails?.firstName?.charAt(0) || user?.username?.charAt(0) || ''}</div>
        </div>

        {user?.userDetails?.firstName &&
          <p>
            {user.userDetails.firstName} {user.userDetails.firstName}
          </p>
        }
        <p>
          {user.username}
        </p>
      </div>
    </div>
  )
}

const mapState = (state) => ({
  user: state.users.data,
});

export default connect(mapState, {})(Profile)